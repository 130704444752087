import Vue from "vue";
import Vuex from "vuex";

import loader from "./modules/loader";
import alert from "./modules/alert";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    state_loading: false,
  },
  getters: {
    stateLoading(state) {
      return state.loading;
    },
  },
  mutations: {},
  actions: {},
  modules: {
    loader,
    alert,
  },
});
