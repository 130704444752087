<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
    <NOTIFICATION v-if="notification.estado" :notification="notification" />
    <ALERTA v-if="alerta.estado" :alerta="alerta" />
    <SPINNER v-if="state_loading" />
  </v-app>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { defineAsyncComponent } from "vue";

export default {
  name: "App",
  components: {
    NOTIFICATION: defineAsyncComponent(() => import("@/components/global/Notification.vue")),
    SPINNER: defineAsyncComponent(() => import("@/components/global/Spinner.vue")),
    ALERTA: defineAsyncComponent(() => import("@/components/global/Alerta.vue")),
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({ state_loading: "loader/$getLoading" }),
    ...mapState("alert", ["notification", "alerta"]),
  },
};
</script>
<style lang="scss">
@import "./assets/app/style";
</style>
