import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const TITLE = "CasaTua";

const routes = [
  {
    path: "/",
    redirect: "/inicio",
  },
  {
    path: "/inicio",
    name: "inicio",
    component: function () {
      return import("../views/page/Home.vue");
    },
    meta: {
      REQUIRED_AUTH: false,
      title: `${TITLE} - inicio  `,
    },
  },
  {
    path: "/ingreso",
    name: "ingreso",
    component: function () {
      return import("../views/admin/Ingreso.vue");
    },
    meta: {
      REQUIRED_AUTH: false,
      title: `${TITLE} - ingreso  `,
    },
  },
  {
    path: "/confirmar-correo",
    name: "confirmar-correo",
    component: function () {
      return import("../views/page/ConfirmarCorreo.vue");
    },
    meta: {
      REQUIRED_AUTH: false,
      title: `${TITLE} - confirmar Correo`,
    },
  },
  {
    path: "/menu",
    name: "menu",
    component: function () {
      return import("../views/page/Menu.vue");
    },
    meta: {
      REQUIRED_AUTH: false,
      title: `${TITLE} - confirmar Correo`,
    },
  },
  {
    path: "/reservas",
    name: "reservas",
    component: function () {
      return import("../views/admin/Reserve.vue");
    },
    meta: {
      REQUIRED_AUTH: true,
      title: `${TITLE} - reservas  `,
    },
    // children: [
    //   {
    //     path: "/inicio",
    //     name: "inicio",
    //     component: function () {
    //       return import("../views/page/Home.vue");
    //     },
    //     meta: {
    //       REQUIRED_AUTH: false,
    //       title: `${TITLE} - inicio  `,
    //     },
    //   },
    // ],
  },
  {
    path: "/miembros",
    name: "miembros",
    component: function () {
      return import("../views/admin/Miembros.vue");
    },
    meta: {
      REQUIRED_AUTH: true,
      title: `${TITLE} - reservas  `,
    },
  },
  {
    path: "/puestos",
    name: "puestos",
    component: function () {
      return import("../views/admin/Puestos.vue");
    },
    meta: {
      REQUIRED_AUTH: true,
      title: `${TITLE} - puestos  `,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  const AUTH = sessionStorage.usuario;
  document.title = to.meta.title;
  const REQUIRED_AUTH = to.matched.some((record) => record.meta.REQUIRED_AUTH);
  const CURENT_USU = AUTH ? JSON.parse(atob(AUTH)) : null;

  if (REQUIRED_AUTH && !CURENT_USU) next("ingreso");
  else if (!REQUIRED_AUTH && CURENT_USU && to.path !== "/reservas") next("reservas");
  else next();
  window.scrollTo({ top: 0, behavior: "smooth" });
});

export default router;
