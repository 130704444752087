const notification = () => {
  return {
    tipo: "",
    code: "",
    estado: false,
    msg: "",
  };
};
const alerta = () => {
  return {
    tipo: "",
    code: "",
    func1: null,
    func2: null,
    estado: false,
    msg: "",
  };
};

export default {
  namespaced: true,
  state: {
    alerta: alerta(),
    notification: notification(),
  },
  mutations: {
    Ntf_(state, data) {
      state.notification.estado = true;
      Object.assign(state.notification, data);
    },
    OffNtf_(state) {
      state.notification.estado = true;
      Object.assign(state.notification, notification());
    },
    Alerta_(state, [code, tipo = "info", msg, func1, func2]) {
      state.alerta.estado = true;
      state.alerta.code = code;
      state.alerta.tipo = tipo;
      state.alerta.msg = msg;
      state.alerta.func1 = func1;
      state.alerta.func2 = func2;
    },
    OffAlerta_(state) {
      state.alerta.estado = false;
      Object.assign(state.alerta, alerta());
    },
  },
};
